import { useEffect, useMemo, useRef, useState } from "react";

import { EUnitPermissionAreas, getTzDayjs } from "@m7-health/shared-utils";
import { useMutation } from "@tanstack/react-query";
import { filter, keyBy, uniq, uniqBy, values } from "lodash";

import { Box, TextField } from "@mui/material";

import { PartialShiftTimePicker } from "~/common/components/PartialShiftTimePicker/PartialShiftTimePicker";
import ShiftTypeFilterDropdown from "~/common/components/ShiftTypeFilterDropdown";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { dateString, KeyBy, seconds, TimeString } from "~/common/types";
import { houseViewStore } from "~/features/HouseView/store";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IShift } from "~/routes/api/types";

import { sendMassSmsApi } from "#/features/Roster/api";
import {
  ISchedule,
  IShiftType,
  IUser,
  StaffShift,
  useListStaffDetailsQuery,
  useListUsersQuery,
  useListWorkingHoursQuery,
  User,
  useShiftTypesForSchedules,
} from "@/api";
import { CustomButton, CustomTabs } from "@/common/components";
import {
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useToast,
} from "@/common/hooks";
import { useErrors } from "@/common/hooks/useErrors";

import { HouseViewSideBar, STAFF_ITEMS_LAYOUTS } from "..";
import { useSelectedSchedule } from "../../../hooks/useSelectedSchedule";
import { StaffItem } from "../../StaffItem";
import { eligibleToWork } from "../helpers/eligibleToWork";
import { FindStaffToWorkFilters } from "../helpers/FindStaffToWorkFilters";
import { useApplyFindStaffToWorkFilters } from "../hooks/useFindStaffToWorkFilters";
import { THouseViewSideBar } from "../types";

const FindStaffToWork = ({ selectedUnit, shifts }: THouseViewSideBar) => {
  const dispatch = useAppDispatch();
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const timezone = useCurrentTimezone(selectedUnit.id);

  const selectedDateForData = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  );
  const selectedStaffCategory = useAppSelector(
    (state) => state.houseView.pageFilters.selectedStaffCategory,
  );
  const actionIsDirty = useAppSelector((state) => state.houseView.sidebarCurrentAction.isDirty);
  const selectedDayjs = useMemo(
    () => getTzDayjs(selectedDateForData, timezone),
    [selectedDateForData, timezone],
  );
  const selectedScheduleId = useSelectedSchedule()?.id;
  const [allShifts, scheduleIds] = useMemo(() => {
    const shiftsArray = values(shifts).flat();
    const uniqScheduleIds = uniq([
      selectedScheduleId || NOT_EXISTING_UUID,
      ...(shiftsArray?.map((shift) => shift.scheduleId) || NOT_EXISTING_UUID),
    ]);
    return [shiftsArray, uniqScheduleIds];
  }, [shifts, selectedScheduleId]);
  const shiftTypes = useShiftTypesForSchedules(scheduleIds);
  const shiftsByStaffId = useMemo(() => keyBy(allShifts, "staffId"), [allShifts]);
  const selectedStaffShift = useAppSelector((state) => state.houseView.findStaffToWork?.staffShift);

  const selectedStaffId = useAppSelector(
    (state) => state.houseView.findStaffToWork?.staffShift?.staffId,
  );
  const selectedShiftsToTextByStaffId = useAppSelector(
    (state) => state.houseView.findStaffToWork?.staffShiftsToText,
  );
  // if looking to text staff
  const isLookingToText = useAppSelector(
    (state) => state.houseView.findStaffToWork.isLookingToText,
  );
  const possibleTextMessage = useRef("");
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const { mutate: mutateMassSmsSubmission, isPending: isLoadingMassSmsSubmission } = useMutation({
    mutationFn: sendMassSmsApi,
    onSuccess: () => {
      showSuccess("Text sent successfully");
      possibleTextMessage.current = "";
      dispatch(houseViewStore.state.setFoundStaffShiftToText(null));
    },
    onError: handleErrors,
  });
  const confirmSendingMassSms = () => {
    const userIds = Object.keys(selectedShiftsToTextByStaffId || {});

    if (window.confirm(`You are about to send an Text to ${userIds.length} staff members.\n`)) {
      mutateMassSmsSubmission({
        userIds: userIds,
        messageContent: possibleTextMessage.current,
      });
    }
  };

  const shiftTypeByScheduleByType = useMemo(
    () =>
      Object.entries(shiftTypes).reduce(
        (acc, [scheduleId, scheduleShiftTypes]) => {
          const typesByKey = keyBy(scheduleShiftTypes, "key");
          acc[scheduleId] = typesByKey;
          return acc;
        },
        {} as Record<ISchedule["id"], KeyBy<IShift, "key">>,
      ),
    [shiftTypes],
  );

  const { data: usersFromUnit } = useListUsersQuery({
    unitIds: [selectedUnit.id],
    roles: [User.ERole.staff],
  });

  const { data: staffDetails = [] } = useListStaffDetailsQuery({
    unitIds: [selectedUnit.id],
  });

  const staffDetailsByStaffId = useMemo(() => keyBy(staffDetails, "userId"), [staffDetails]);

  const eligibleStaffs = useMemo(() => {
    const staffToKeep: IUser[] = [];
    const nonEligibleStaff: Record<IUser["id"], true> = {};

    // eligible:
    // - staff eligible for this unit (or home unit, or other eligible unit (eligible includes home))
    // - that OR:
    //   - are not to be deactivated
    //   - don't have a shift
    //   - not paid && not working
    allShifts?.forEach((shift) => {
      const shiftType = shiftTypeByScheduleByType[shift.scheduleId]?.[shift.shiftTypeKey];

      if (!shiftType || !eligibleToWork(shiftType)) nonEligibleStaff[shift.staffId] = true;
    });

    const currentDate = selectedDayjs.toDate();

    staffDetails?.forEach((staff) => {
      const postDeletedDate = staff.postDateStatusUpdateDate
        ? new Date(staff.postDateStatusUpdateDate)
        : null;

      if (postDeletedDate && currentDate > postDeletedDate) nonEligibleStaff[staff.userId] = true;
    });

    // Push the staffs that are not non-eligible
    usersFromUnit?.forEach((user) => {
      if (!nonEligibleStaff[user.id]) staffToKeep.push(user);
    });

    return staffToKeep;
  }, [allShifts, selectedDayjs, shiftTypeByScheduleByType, staffDetails, usersFromUnit]);

  const { data: staffWorkingHours = [] } = useListWorkingHoursQuery(
    {
      dateRanges: [
        {
          from: selectedDayjs.startOf("week").toDate().toISOString(),
          to: selectedDayjs.endOf("week").toDate().toISOString(),
        },
      ],
      shiftType: {
        isWorkingShift: true,
        isPaidShift: true,
      },
    },
    { skip: !eligibleStaffs.length },
  );
  const staffWorkingHoursByStaffId = useMemo(
    () => keyBy(staffWorkingHours, "staffId"),
    [staffWorkingHours],
  );

  const selectShift = (staffId: IUser["id"]) => {
    dispatch(houseViewStore.state.setFoundStaffShift({ staffId }));
  };

  const selectShiftsToText = (staffId: IUser["id"]) => {
    dispatch(houseViewStore.state.setFoundStaffShiftToText({ staffId }));
  };

  const partialShiftAttributes = useRef<{
    customStartTime: TimeString | null;
    customDuration: seconds | null;
  }>({
    customStartTime: null,
    customDuration: null,
  });
  const [partialShiftIsValid, setPartialShiftIsValid] = useState(true);

  const filteredEligibleStaffs = useApplyFindStaffToWorkFilters(
    eligibleStaffs,
    shiftsByStaffId,
    staffDetailsByStaffId,
    staffWorkingHoursByStaffId,
    shiftTypeByScheduleByType,
  );

  // When filtered eligible staff changes, re-select staffs to text
  useEffect(() => {
    dispatch(houseViewStore.state.setFoundStaffShiftToText(null));
    filteredEligibleStaffs.forEach((staff) => {
      if (selectedShiftsToTextByStaffId?.[staff.id]) {
        selectShiftsToText(staff.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEligibleStaffs]);

  const availableCategories = filter(
    uniqBy(
      eligibleStaffs.map((staff) => staffDetailsByStaffId[staff.id]?.staffType?.staffCategory),
      "key",
    ),
  );

  if (!selectedScheduleId) return <></>;
  const scheduleShiftTypes = shiftTypes[selectedScheduleId];
  const saveNewShift = () => {
    const shiftTypeKey = selectedStaffShift?.shiftTypeKey;
    const staffId = selectedStaffShift?.staffId;
    if (staffId && shiftTypeKey) {
      const now = new Date().toISOString() as dateString;
      const timestamps = { createdAt: now, updatedAt: now, deletedAt: null };

      const workingAwayFromHomeUnit =
        staffDetailsByStaffId[staffId]?.homeUnitId !== selectedUnit.id;

      const positionAsCategory = selectedUnit?.attributes?.find(
        (attribute) => attribute.name.toLowerCase() === selectedStaffCategory,
      );
      const staffAvailableCategoryAsPosition =
        positionAsCategory &&
        staffDetailsByStaffId?.[staffId]?.attributeKeys?.find(
          (key) => key === positionAsCategory?.key,
        );

      dispatch(
        houseViewStore.state.startEditShifts({
          staffId,
          shifts: [
            {
              id: window.crypto.randomUUID(),
              shiftTypeKey,
              staffId,
              date: selectedDayjs.toDate().toISOString() as dateString,
              scheduleId: selectedScheduleId,
              scheduleType: StaffShift.EScheduleType.draft,
              status: workingAwayFromHomeUnit ? StaffShift.EStatus.floated : null,
              isWorkingAway: workingAwayFromHomeUnit,
              attributes: staffAvailableCategoryAsPosition ? [positionAsCategory.key] : [],
              ...selectedStaffShift,
              ...partialShiftAttributes.current,
              ...timestamps,
            },
          ],
          autoSave: true,
        }),
      );
    }
  };

  return (
    <>
      <Box height="42px" mb={1}>
        <MixpanelProvider properties={{ [Mxp.Property.layout.component]: "filters" }}>
          <FindStaffToWorkFilters
            filteredInCount={filteredEligibleStaffs.length}
            shiftTypeByScheduleByType={shiftTypeByScheduleByType}
            availableCategories={availableCategories}
          />
        </MixpanelProvider>
      </Box>
      {!userIsKiosk && canManage && (
        <Box height="82px" mb={1}>
          <CustomTabs<string>
            onChange={(newTabVal) => {
              dispatch(houseViewStore.state.selectIsLookingToText(newTabVal === "textStaff"));
            }}
            tabs={[
              {
                label: "Find Staff",
                value: "findStaff",
                sx: { px: 6 },
              },
              {
                label: "Text Staff",
                value: "textStaff",
                sx: { px: 6 },
              },
            ]}
            value={isLookingToText ? "textStaff" : "findStaff"}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            pillTabs
          />
          {isLookingToText && (
            <CustomButton
              label={`Select All Staff (${values(selectedShiftsToTextByStaffId).length}/${filteredEligibleStaffs.length})`}
              onClick={() => {
                dispatch(houseViewStore.state.setFoundStaffShiftToText(null));
                filteredEligibleStaffs.forEach(({ id }) => {
                  selectShiftsToText(id);
                });
              }}
              fullWidth
              color="darkPurple"
            />
          )}
        </Box>
      )}
      <Box
        sx={{ overflowY: "auto", overflowX: "hidden" }}
        key={`${isLookingToText ? "texting" : "working"}`}
      >
        {filteredEligibleStaffs.map(({ id }) => {
          const staff = staffDetailsByStaffId?.[id];

          const selected = isLookingToText
            ? !!selectedShiftsToTextByStaffId && id in selectedShiftsToTextByStaffId
            : selectedStaffId === id;

          const nonKioskUserParams =
            userIsKiosk || !canManage
              ? {}
              : {
                  multiSelectable: isLookingToText,
                  selectable: !isLookingToText,
                  onClick: () => {
                    isLookingToText ? selectShiftsToText(id) : selectShift(id);
                  },
                };

          return (
            <StaffItem
              unitId={selectedUnit.id}
              layout={STAFF_ITEMS_LAYOUTS.findStaffToWork}
              staffDetails={staff}
              selected={selected}
              {...nonKioskUserParams}
            />
          );
        })}
      </Box>
      <Box flexGrow={1} />

      {/* Set the shift data and save */}
      {selectedStaffShift?.staffId && !isLookingToText && (
        <HouseViewSideBar.Helpers.BottomActions
          title="Shift Details"
          actionButton={{
            label: "Assign Staff",
            disabled: !selectedStaffShift.shiftTypeKey || !partialShiftIsValid,
            action: saveNewShift,
          }}
          cancelButton={{
            label: "Cancel",
            disabled: !actionIsDirty && selectedStaffShift?.staffId === null,
            action: () => {
              dispatch(houseViewStore.state.setFoundStaffShift(null));
            },
          }}
        >
          <ShiftTypeFilterDropdown
            shiftTypes={(scheduleShiftTypes || []).filter((shiftType) => shiftType.isWorkingShift)}
            selectedOption={scheduleShiftTypes?.find(
              (shiftType) => shiftType.key === selectedStaffShift?.shiftTypeKey,
            )}
            selectOption={(shiftType) => {
              dispatch(
                houseViewStore.state.setFoundStaffShift({
                  shiftTypeKey: shiftType?.key as IShiftType["key"] | undefined,
                }),
              );
              partialShiftAttributes.current = {
                customDuration: null,
                customStartTime: null,
              };
            }}
            isMultiSelect={false}
          />
          <PartialShiftTimePicker
            shiftType={
              scheduleShiftTypes?.find(
                (shiftType) => shiftType.key === selectedStaffShift?.shiftTypeKey,
              ) || null
            }
            alwaysOn
            staffShift={selectedStaffShift}
            onChange={(shiftAttributes, isValid) => {
              if (isValid) {
                partialShiftAttributes.current = {
                  customDuration: shiftAttributes.customDuration || null,
                  customStartTime: shiftAttributes.customStartTime || null,
                };
                setPartialShiftIsValid(true);
              } else {
                setPartialShiftIsValid(false);
              }
            }}
          />
        </HouseViewSideBar.Helpers.BottomActions>
      )}
      {selectedShiftsToTextByStaffId && isLookingToText && (
        <HouseViewSideBar.Helpers.BottomActions
          title="Send Text"
          actionButton={{
            label: "Send Text",
            disabled:
              Object.keys(selectedShiftsToTextByStaffId || {}).length === 0 ||
              isLoadingMassSmsSubmission,
            action: () => {
              confirmSendingMassSms();
            },
          }}
          cancelButton={{
            label: "Cancel",
            disabled: !actionIsDirty,
            action: () => {
              dispatch(houseViewStore.state.setFoundStaffShiftToText(null));
            },
          }}
        >
          <TextField
            fullWidth
            multiline
            onChange={(event) => (possibleTextMessage.current = event.target.value)}
            placeholder="Enter your message here"
            minRows={4}
          />
        </HouseViewSideBar.Helpers.BottomActions>
      )}
    </>
  );
};

export const __HouseViewSideBarFindStaffToWork = FindStaffToWork;
